/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import Loading from '@domui-components/Loading';
import Metadata from '@domui-utils/workflow/metadata';

const ExpertiseAreasPage: React.FC = () => {
  const [expertiseAreasData, setExpertiseAreasData] = useState<any>({});

  useEffect(() => {
    async function fetchCountryData() {
      const wfService = new Metadata();
      const response = await wfService.getExpertiseAreas();
      setExpertiseAreasData(response);
    }
    fetchCountryData();
  }, []);

  if (!expertiseAreasData) {
    return <Loading />;
  }

  return (
    <div>
      {!!expertiseAreasData?.length && (
        <div style={{ alignItems: 'center' }} className="max-w-4xl mb-8">
          <h3>ExpertiseAreas Page</h3>
          <ul>
            {expertiseAreasData.map((item: any) => (
              <div key={item.id}>
                <li className="mb-0 last:mb-4">
                  {item.id} - {item.value}
                </li>
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ExpertiseAreasPage;
